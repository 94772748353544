import React from 'react'
import styled from 'styled-components';


import { GenericHistoryViewModel } from '../../model/GenericHistoryViewModel';
import VersionHistoryComponent from './VersionHistoryComponent';


interface IProps {
    view: GenericHistoryViewModel
}

const Style = styled.div`

h2{
    margin-top: 3rem;
}


`;

const GenericHistoryComponent: React.FC<IProps> = (props: IProps) => {


    return <Style  >
    <h2>Version History</h2>
    { !props.view.versions.length && <p>No Version History</p>}
    { !!props.view.versions.length && props.view.versions.map((x,i)=><VersionHistoryComponent key={i} view={x}  />)}
    </Style>
}

export default GenericHistoryComponent;