import axios, { AxiosRequestConfig } from 'axios';
import { Store } from 'redux';
import IState from '../model/IState';
import { SetStocks } from '../actions/SetStocks';
import { IApi } from './IApi';
import Stock, { EmailMarketingSettings } from '../model/Stock';
import DashboardStock from '../model/DashboardStock';
import { Announcement } from '../model/Stock';
import { SendAnnouncementResult } from '../model/SendAnnouncementResult';
import { SaveAnnouncementResult } from '../model/SaveAnnouncementResult';
import SystemSettings from '../model/SystemSettingsViewModel';
import SaveStockResult from '../model/SaveStockResult';
import { GenerateReportRequest } from '../model/GenerateReportRequest';
import { GenerateReportResult } from '../model/GenerateReportResult';
import { AdminReportsView } from '../model/AdminReportsView';
import { ReportViewModel } from '../model/ReportViewModel';
import { AdminInvoiceResult } from '../model/AdminInvoiceResult';
import { LoginResult } from '../model/LoginResult';
import { SaveInvoiceDataRequest } from '../model/SaveInvoiceDataRequest';
import { FileCleanupResult } from '../model/FileCleanupResult';
import { MailChimpSubscriberCountResult } from '../model/MailChimpSubscriberCountResult';
import { SaveNoteRequest } from '../model/SaveNoteRequest';
import { SaveNoteResult } from '../model/SaveNoteResult';
import { GetNotesResult } from '../model/GetNotesResult';
import { SyncWatchlistResult } from '../model/SyncWatchlistResult';
import { XeroPageViewModel } from '../model/XeroPageViewModel';
import { AssignXeroInvoicesRequest } from '../model/AssignXeroInvoicesRequest';
import { ChangeTickerRequest, ChangeTickerResult } from '../model/ChangeTickerRequest';
import { to } from '../util/util';
import ServiceOption from '../model/ServiceOption';
import ServiceOrder from '../model/ServiceOrder';
import { GetServiceOrdersResult } from '../model/GetServiceOrdersResult';
import { CreateStockFromServiceOrderRequest, CreateStockFromServiceOrderResult } from '../model/CreateStockFromServiceOrderRequest';
import { CreateContactResult } from '../model/CreateContactResult';
import { CreateXeroRepeatingInvoiceResult } from '../model/CreateXeroRepeatingInvoiceResult';
import { ArchiveStockRequest, ArchiveStockResult } from '../model/ArchiveStockRequest';
import { DeleteAnnouncementResult } from '../model/DeleteAnnouncementResult';
import { ReconcileResult } from '../model/ReconcileResult';
import { MailChimpApiTestCampaignArgs } from '../model/MailChimpApiTestCampaignArgs';
import { DiskReportRequest, DiskReportResult } from '../model/DiskReportResult';
import { SaveEmailMarketingSettingsRequest, SaveEmailMarketingSettingsResult } from '../model/SaveEmailMarketingSettingsResult';
import { SendEmailRequest, SendEmailResult } from '../model/SendEmailRequest';
import { ResyncAnnouncementsResult } from '../model/ResyncAnnouncementsResult';
import { EmailMarketingApiResult } from '../model/EmailMarketingApiResult';
import { UpdateChartDataResult } from '../model/UpdateChartDataResult';
import { GetChartDataResult } from '../model/GetChartDataResult';
import { ClientGenerateReportRequest } from '../model/ClientGenerateReportRequest';
import { SendConfirmationAnnouncementEmailResult } from '../model/SendConfirmationAnnouncementEmailResult';
import { GenericHistoryViewModel } from '../model/GenericHistoryViewModel';
import { AnnouncementCommentResponseViewModel } from '../model/AnnouncementCommentResponseViewModel';
import { AnnouncementCommentResponseRequest, AnnouncementCommentResponseResult } from '../model/AnnouncementCommentResponseRequest';
import { AnnouncementCommentsResult } from '../model/AnnouncementCommentsResult';
import { GetUsersResult, SaveUserResult } from '../model/GetUsersResult';
import { GetUserResult, SaveUserRequest } from '../model/GetUserResult';
import { ClientDashboardResult } from '../model/ClientDashboardResult';
import { ClientStockResult } from '../model/ClientStockResult';
import { GetStockRefererResult } from '../model/StockReferer';
axios.defaults.withCredentials = true;

class Api implements IApi {
    
    baseUrl:string = '';
    
    constructor(public store: Store<IState>) { 
        
        
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {                        
            this.baseUrl = 'http://localhost:5100';
        }        
    }

    getConfig(params?:{}) : AxiosRequestConfig {        
        
        let config:AxiosRequestConfig = {
            params
        }
        
        return config;
    }

    async getStocks(): Promise<DashboardStock[]> {

        const response = await this.get(`${this.baseUrl}/api/admin/stocks`);        
        return response.data;
    }
    
    async loadDashboardStocks(): Promise<void> {

        const stocks = await this.getStocks(); 
        this.store.dispatch(SetStocks(stocks));
    }

    async getStock(ticker:string): Promise<Stock> {
        const response = await this.get(this.getUrl(`/api/stock/${ticker}`));
        //await this.sleep(2500);
        return response.data;
    }
    
    async getFileCleanupResult(): Promise<FileCleanupResult> {
        const response = await this.get(this.getUrl(`/api/admin/fileCleanup`));
        return response.data;
    }

    async getDiskReportResult(request:DiskReportRequest): Promise<DiskReportResult> {
        const url = this.getUrl(`/api/admin/diskReport`);
        const response = await this.get(url, request);
        return response.data;
    }

    async getNewApiKey() : Promise<string> {
        const response = await this.get(this.getUrl(`/api/admin/apiKey`));
        return response.data;
    }

    async getInvoices(ticker:string) : Promise<AdminInvoiceResult> {
        const response = await this.get(this.getUrl(`/api/admin/invoices`), { ticker });
        return response.data;
    }

    async getXeroPage() : Promise<XeroPageViewModel> {
        const response = await this.get(this.getUrl(`/api/admin/getXeroPage`));
        return response.data;
    }

    async refreshXero() : Promise<XeroPageViewModel> {
        const response = await this.post(this.getUrl(`/api/admin/refreshXero`), {});
        return response.data;
    }

    async assignXeroInvoices(request:AssignXeroInvoicesRequest) : Promise<XeroPageViewModel> {        
        const response = await this.post(this.getUrl('/api/admin/assignXeroInvoices'), request);
        return response.data;
    }

    async syncWatchlist() : Promise<SyncWatchlistResult> {
        const response = await this.post(this.getUrl(`/api/admin/syncWatchlist`), {});
        return response.data;
    }

    async runPriorDay() : Promise<void> {
        await this.post(this.getUrl(`/api/admin/runPriorDay`), {});        
    }

    async resyncAnnouncements(ticker:string) : Promise<ResyncAnnouncementsResult> {
        const response = await this.post(this.getUrl(`/api/admin/resyncAnnouncements?ticker=${ticker}`), {});
        return response.data;
    }

    async get(url:string, params?:{}) : Promise<any> {
        const [err, result ] = await to(axios.get(url, this.getConfig(params)))
        if(err){
            if(err.request && err.request.status===401){
                window.location.reload();
            }else{
                throw err;
            }            
            
        }else{
            return result;
        }
    }

    async sleep(ms: any): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getUrl(path:string) : string {
        return `${this.baseUrl}${path}`;
    }

    async saveMarketData(ticker:string, marketData:any) : Promise<void>{
        return this.post(this.getUrl(`/api/admin/saveMarketData/${ticker}`), marketData);
    }

    async saveStockInvoiceData(request:SaveInvoiceDataRequest) : Promise<void>{
        return this.post(this.getUrl(`/api/admin/saveInvoiceData/${request.Ticker}`), request);
    }

    async saveStock(stock:Stock) : Promise<SaveStockResult> {
        const result = await this.post(this.getUrl(`/api/admin/stocks`), stock);        
        return result.data;
        // await this.sleep(2500);        
    }
     async post(url:string, payload:any) : Promise<any> {
        return await axios.post(url, payload, this.getConfig());
     }

    async deleteAnnouncement(ticker: string, announcement: Announcement): Promise<DeleteAnnouncementResult> {
        let result = await this.post(this.getUrl(`/api/Announcement/delete/${ticker}`), announcement);
        return result.data;
    }

    async sendAnnouncement(ticker: string, announcement: Announcement, sendOverride:boolean): Promise<SendAnnouncementResult> {
        let result = await this.post(this.getUrl(`/api/Announcement/send/${ticker}?sendOverride=${sendOverride}`), announcement);
        return result.data;
    }

    async saveAnnouncement(announcement:Announcement, existingAnnouncement:Announcement|null, file:File|null, ticker: string) : Promise<SaveAnnouncementResult> {
        const form = new FormData();

        if (file){
            form.append('file', file);
        }
          
        form.append('headline', announcement.headline);
        form.append('date', announcement.date);
        if(announcement.remotePath){
            form.append('remotePath', announcement.remotePath!);
        }
        
        if(existingAnnouncement){
          form.append('existingAnnouncementHeadline', existingAnnouncement.headline);
          form.append('existingAnnouncementDate', existingAnnouncement.date);
        }
        
        if (announcement.priceSensitive)
          form.append('priceSensitive', 'true');

        if (announcement.videoUrl)
            form.append('videoUrl', announcement.videoUrl);

        if (announcement.videoDescription)
            form.append('videoDescription', announcement.videoDescription);

        let result = await this.post(this.getUrl(`/api/Announcement/save/${ticker}`), form);
        return result.data
      }

      async getSystemSettings() : Promise<SystemSettings> {
        const result = await this.get(this.getUrl(`/api/admin/systemSettings`));        
        return result.data;
      }

      async saveSystemSettings(settings:SystemSettings) : Promise<SystemSettings> {
        const result = await this.post(this.getUrl(`/api/admin/systemSettings`), settings);        
        return result.data;
    }

    async generateReport(request: GenerateReportRequest): Promise<GenerateReportResult> {
        
        const form = new FormData()
        Object.keys(request).forEach((key) => {
            let value = (request as any)[key];
            if(value !== null){
                form.append(key, value)
            }
            
        })        
        console.log('request', request)
        let result = await this.post(this.getUrl(`/api/admin/generateReport`), form);
        return result.data
    }

    async getReport(guid: string): Promise<ReportViewModel> {
        const result = await this.get(this.getUrl(`/api/report/${guid}`));
        return result.data;
    }

    async getReports(ticker: string): Promise<AdminReportsView> {
        const url = this.getUrl(`/api/admin/reports`)
        const result = await this.get(url, { ticker });
        return result.data;
    }

    async login(username: string, password: string): Promise<LoginResult> {
        const config = {
            auth: {
                username,
                password,
            }
        };
        
        return axios.get(this.getUrl(`/api/login`), config);        
    }

    async refreshToken() : Promise<LoginResult> {        
        const result = await axios.get(this.getUrl('/api/admin/refreshtoken'))
        return result.data;
    }

    logout() : Promise<void> {
        return axios.delete(this.getUrl(`/api/login`))
    }


    async mailchimpSubscriberCount(apiKey:string, listId:string, clientId:string, stockId:string) : Promise<MailChimpSubscriberCountResult> {
        const result = await this.get(this.getUrl('/api/EmailMarketingSubscriberCount'), { apiKey, listId, clientId, stockId })
        return result.data;
    }

    async getNotes(ticker:string) : Promise<GetNotesResult> {
        const result = await this.get(this.getUrl(`/api/admin/getNotes`), { ticker })
        return result.data;
    }

    async getStockVersionHistory(ticker:string) : Promise<GenericHistoryViewModel> {
        const result = await this.get(this.getUrl(`/api/admin/getStockVersionHistory`), { ticker })
        return result.data;
    }

    async saveNote(request:SaveNoteRequest) : Promise<SaveNoteResult> {
        const result = await this.post(this.getUrl(`/api/admin/saveNote`), request);        
        return result.data;
    }
    
    async changeTicker(request:ChangeTickerRequest) : Promise<ChangeTickerResult> {
        const result = await this.post(this.getUrl(`/api/admin/changeTicker`), request);        
        return result.data;         
    }

    async getServiceOptions(groupId:number|undefined) : Promise<{ options: ServiceOption[]}> {
        const result = await this.get(this.getUrl('/api/serviceorder/services/'), { groupId } );        
        return result.data;         
    }
    async saveServiceOrder(order:ServiceOrder) : Promise<{ id: string}> {
        const result = await this.post(this.getUrl(`/api/serviceorder/`), order);        
        return result.data; 
    }

    async getServiceOrders() : Promise<GetServiceOrdersResult> {
        const result = await this.get(this.getUrl(`/api/admin/serviceOrders/`));        
        return result.data; 
    }

    async createStockFromServiceOrder(request:CreateStockFromServiceOrderRequest) : Promise<CreateStockFromServiceOrderResult> {
        const result = await this.post(this.getUrl(`/api/admin/createStockFromServiceOrder/`), request);        
        return result.data; 
    }
    async createContact(id:string) : Promise<CreateContactResult> {
        const result = await this.post(this.getUrl(`/api/admin/createContact?id=${id}`), null);    
        return result.data;            
    }

    async getServiceOrder(ticker:string) : Promise<ServiceOrder> {
        const result = await this.get(this.getUrl(`/api/admin/getServiceOrder`), { ticker });              
        return result.data;
    }

    async createXeroRepeatingInvoice(id:string, amount:number) : Promise<CreateXeroRepeatingInvoiceResult> {
        const result = await this.post(this.getUrl(`/api/admin/createXeroRepeatingInvoice`), { id, amount});    
        return result.data;            
    }

    async archiveStock(request:ArchiveStockRequest) : Promise<ArchiveStockResult> {
        const result = await this.post(this.getUrl(`/api/admin/archiveStock`), request);        
        return result.data;         
    }
    async reconcile(numDays:number) : Promise<ReconcileResult> {
        const result = await this.post(this.getUrl(`/api/reconcile/run?numDays=${numDays}`), {});        
        return result.data;         
    }
    async getReconcileResult(id:string) : Promise<ReconcileResult> {
        const result = await this.get(this.getUrl(`/api/reconcile/get/${id}`));        
        return result.data;         
    }
    async reconcileApply(id:string) : Promise<void> {
        const result = await this.post(this.getUrl(`/api/reconcile/apply/${id}`), {});        
        return result.data;         
    }
    async getLastReconcileId() : Promise<string> {
        const result = await this.get(this.getUrl(`/api/reconcile/last/`));        
        return result.data;         
    }
    async sendToSubscribers(args:MailChimpApiTestCampaignArgs) : Promise<SendAnnouncementResult> {
        const result = await this.post(this.getUrl(`/api/TestSendAnnouncement/sendToSubscribers`), args);        
        return result.data;         
    }
    async sendTestEmail(args:MailChimpApiTestCampaignArgs) : Promise<EmailMarketingApiResult> {
        const result = await this.post(this.getUrl(`/api/TestSendAnnouncement/sendTestEmail`), args);        
        return result.data;         
    }
    async getEmailMarketingSettings(id:string) : Promise<EmailMarketingSettings> {
        const result = await this.get(this.getUrl('/api/emailMarketingSettings/'), { id });        
        return result.data;         
    }
    async saveEmailMarketingSettings(request:SaveEmailMarketingSettingsRequest) : Promise<SaveEmailMarketingSettingsResult> {
        const result = await this.post(this.getUrl('/api/emailMarketingSettings/'), request);        
        return result.data;         
    }
    async sendEmail(request:SendEmailRequest) : Promise<SendEmailResult> {
        const result = await this.post(this.getUrl('/api/admin/sendEmail'), request);        
        return result.data;         
    }
    async getExchanges() : Promise<string[]> {
        const result = await this.get(this.getUrl('/api/admin/exchanges/'));        
        return result.data;         
    }
    async getChartData(id:string) : Promise<GetChartDataResult> {
        const result = await this.get(this.getUrl('/api/admin/getChartData/'), { id });        
        return result.data;         
    }
    async updateChartData(id:string) : Promise<UpdateChartDataResult> {
        const result = await this.post(this.getUrl(`/api/admin/updateChartData/`), { id });        
        return result.data;         
    }
    async clientGenerateReportRequest(request:ClientGenerateReportRequest) : Promise<GenerateReportResult> {
        const result = await this.post(this.getUrl('/api/report/generateReport'), request);        
        return result.data;         
    }
    async getXeroReminderEmail(ticker:string) : Promise<string> {
        const result = await this.get(this.getUrl(`/api/admin/xeroReminderEmail/${ticker}`));        
        return result.data;         
    }
    async sendConfirmationAnnouncementEmail(ticker: string, announcement: Announcement): Promise<SendConfirmationAnnouncementEmailResult> {
        let result = await this.post(this.getUrl(`/api/admin/sendConfirmationAnnouncementEmail/${ticker}`), announcement);
        return result.data;
    }
    async getAnnouncementCommentResponse(guid: string): Promise<AnnouncementCommentResponseViewModel> {
        const result = await this.get(this.getUrl(`/api/AnnouncementCommentResponse/${guid}`));
        return result.data;
    }
    async postAnnouncementCommentResponseRequest(request:AnnouncementCommentResponseRequest): Promise<AnnouncementCommentResponseResult> {
        let result = await this.post(this.getUrl(`/api/AnnouncementCommentResponse/${request.guid}`), request);
        return result.data;
    }
    async getAnnouncementCommentsResult(combinedTicker:string) : Promise<AnnouncementCommentsResult> {
        const result = await this.get(this.getUrl('/api/AnnouncementComments/'), { combinedTicker });        
        return result.data;         
    }
    async getUsers() : Promise<GetUsersResult> {
        const result = await this.get(this.getUrl('/api/users/'));        
        return result.data;         
    }
    async getUser(id:string) : Promise<GetUserResult> {
        const result = await this.get(this.getUrl(`/api/users/${id}`));        
        return result.data;         
    }
    async saveUser(id:string, user:SaveUserRequest) : Promise<SaveUserResult>{
        let result = await this.post(this.getUrl(`/api/users/${id}`), user);
        return result.data;
    }
    async getClientDashboardResult() : Promise<ClientDashboardResult> {
        const result = await this.get(this.getUrl('/api/clientDashboard/'));        
        return result.data;         
    }
    async getClientStockResult(combinedTicker:string) : Promise<ClientStockResult> {
        const result = await this.get(this.getUrl('/api/ClientStock/'), { combinedTicker });        
        return result.data;         
    }
    async getReferers(ticker:string|undefined) : Promise<GetStockRefererResult> {
        const result = await this.get(this.getUrl('/api/admin/stockReferers'), { ticker });        
        return result.data;         
    }
   
}
let instance: IApi;
export const setup = (store: any, api: IApi = new Api(store)) => {
    instance = api;
};
export default function get() {
    return instance;
};

export function getCombinedTicker(stock:Stock) : string {
    return `${combinedTicker(stock.ticker, stock.exchange)}`;
}
export function combinedTicker(ticker:string, exchange:string) : string {
    return `${ticker}:${exchange}`;
}