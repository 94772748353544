import React, { useState } from 'react';
import styled from 'styled-components'
import useAsyncEffect from 'use-async-effect';
import api, { combinedTicker } from '../../api/api';
import { GetStockRefererResult, RefererSummary } from '../../model/StockReferer';
import SortableTable from '../table/SortableTable';
import ReferersItem from './ReferersItem';
const Style = styled.div`


`;



    const getHeaders = () =>  {
        return [ 'Ticker', 'Referers' ];    
    }
    
const ReferersPage:React.FC = () => {
    
    const [ result, setResult ] = useState<GetStockRefererResult>();
    useAsyncEffect(async (isMounted)=>{
        const result = await api().getReferers(undefined);
        if(isMounted()){
            setResult(result)
        }
    }, []);
    
    const cellFormatter = (columnName:string, cell:any):React.ReactNode => {
        if(columnName ==='Referers'){
            
            const referers = cell as RefererSummary[]
            return <ReferersItem referers={referers} />
            
        }
        return null;
    }

    const data:any[][] = [];
    if(result){
        for(let x of result.referers){
            let arr:any[] = []
            
            arr = [
                combinedTicker(x.ticker, x.exchange),
                x.referers 
            ]
            
            data.push(arr)
        }
    }
    

    return <Style>
        <h2>Referers</h2>
        { !!result && <SortableTable headers={getHeaders()} data={data} cellFormatter={cellFormatter}  />}
    </Style>
}

export default ReferersPage;