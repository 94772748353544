import React from 'react';
import styled from 'styled-components'
import { UserPermission } from '../../model/UserPermission';
import * as Roles from '../../model/Roles';
interface IProps {
    permission:UserPermission
    onCheckChanged:(combinedTicker:string, role:string, isChecked:boolean)=>void;
}

const AllRoles =[ Roles.ViewConfig, Roles.TestSendAnnouncement, Roles.EditAnnouncements,Roles.EditMailchimp,Roles.ReplyToComment, Roles.SendMailchimp, Roles.Invoicing ]

const TdWrapper = styled.td`
input[type='checkbox'] {
    width:auto;
    margin-right:0.5rem;
}
.role-checkbox-container {
    display:flex;
    align-items: center;
    margin: 0.5rem 0;

    & > span {
        user-select:none;
    }
}

`;
const UserPermissionRow:React.FC<IProps> = (props:IProps) => {
    
    const { combinedTicker, companyName, roles  } = props.permission;
  
    return <>
        
        <td>{combinedTicker}</td>
        <td>{companyName}</td>
        <TdWrapper>

            { AllRoles.map((x,i)=> {
                return <label className="role-checkbox-container" key={i}>
                    <input type='checkbox' 
                    checked={roles.includes(x)} 
                    onChange={(e)=>props.onCheckChanged(combinedTicker, x, e.target.checked)}>
                        </input>
                        <span> {x}</span>
                    </label>
            })}

        </TdWrapper>
       

        </>
}

export default UserPermissionRow;