import React from 'react';
import { RefererSummary } from '../../model/StockReferer';
import moment from 'moment';
interface IProps{
    referers: RefererSummary[]
}
const ReferersItem:React.FC<IProps> = (props:IProps) => {
const style:any = { 'fontSize': '0.75rem', 'marginLeft': '1rem'}
return <div>
            {props.referers.map((x, i) => (
        <p key={i}><a href={x.url} target='_blank' rel="noreferrer">{x.url}</a> <strong>{x.count}</strong>   
        <span style={style}>{moment(x.first).format('DDMMMYY')} - {moment(x.last).format('DDMMMYY')}</span>         
        </p>
      ))}
            </div>
}

export default ReferersItem;
