import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SortableTable from '../table/SortableTable'
import Stock from '../../model/DashboardStock'
import { getWebsiteUrl, getDisplayUrl } from './DashboardHelpers';
import moment from 'moment'
import api, { combinedTicker } from '../../api/api'
import { getInvoiceUrl } from '../../util/util'
import { useSelector } from 'react-redux'
import { IGlobalState } from '../../model/IGlobalState'
import * as Roles from '../../model/Roles';
import { User } from '../../model/User'
import { FaCaretDown } from 'react-icons/fa'

interface IProps {
    stocks:Stock[];
}

const Style = styled.div`
display:none;
@media (min-width: 768px) {
    display:block;
  }

.overdue {
    color:red;
    font-weight: bold;
}
`
const TickerColumn = 'Ticker';
const WebsiteColumn = 'Website';
const InvoiceColumn = 'Invoice';
const RepeatColumn = 'Repeat';
const ExpiredColumn = 'Expired';
const CreatedColumn = 'Created';



const getHeaders = (user:User) =>  {
    if(user.roles.hasOwnProperty(Roles.Admin)){
        return [
        TickerColumn,
        CreatedColumn,    
        'API Key',    
        'Poll',
        'Mailchimp',    
        InvoiceColumn,
        RepeatColumn,
        ExpiredColumn,
        WebsiteColumn
    ];
    }else if(user.roles.hasOwnProperty(Roles.RunReports)){
        return [
            TickerColumn,
            CreatedColumn,                
            WebsiteColumn
        ]
    }
    
    return [];
    
}





const DashboardTable:React.FC<IProps> = (props:IProps) => {
    
    const user = useSelector((state: IGlobalState) => state.user);
    
    const getEmailTemplate = async (ticker:string)=>{
        let html = await api().getXeroReminderEmail(ticker)
        console.log(html);
        // navigator.clipboard.writeText(result);

        const clipboardItem = new
        ClipboardItem({'text/html':  new Blob([html],
                                              {type: 'text/html'}),
                       'text/plain': new Blob([html],
                                              {type: 'text/plain'})});
        navigator.clipboard.write([clipboardItem]).then(_ => alert("Email reminder copied to clipboard"),
                 error => alert(error));


    }

    
    const data:any[][] = [];
    for(let stock of props.stocks){
        const ticker = `${combinedTicker(stock.ticker, stock.exchange)}`;
        let arr:any[] = []
        
        if(user.roles.hasOwnProperty(Roles.Admin)){
            arr = [
                ticker,
                stock.created,            
                stock.apiKey || '',            
                stock.pollAsx ? '✓' : '✕',
                stock.mailchimp ? '✓' : '✕',            
                ticker, //invoice column
                ticker, //repeat column
                stock.subscriptionExpired ? '✓' : '✕', 
                stock.websiteUrl || ''
            ]
        }else if(user.roles.hasOwnProperty(Roles.RunReports)){
            arr = [
                ticker,
                stock.created,                            
                stock.websiteUrl || ''
            ]
        }

        
        data.push(arr)
    }

    

const cellFormatter = (columnName:string, cell:any):React.ReactNode => {
    if(columnName===TickerColumn){
        const stock = props.stocks.find(x=>combinedTicker(x.ticker, x.exchange)===cell)!;        
        const strValue = (cell as string).replace(":ASX", "");
        return <Link to={`/stocks/${cell}`} title={`Created: ${moment(stock.created).format('DD MMM YY')}`}>{strValue}</Link>
    }else if(columnName===CreatedColumn){
        
        return moment(cell).format('DD MMM YY')        
    }
    else if(columnName===WebsiteColumn){
        if(!cell){
            return null;
        }
        const stock = props.stocks.find(x=>x.websiteUrl===cell)!;
        if(!stock){
            console.error(`unable to find stock with website ${cell}`)
            return null
        }
        return <a target="_blank"
        href={getWebsiteUrl(stock)}
        rel="noopener noreferrer"
        className="dashboard-row__website"
      >{getDisplayUrl(stock.websiteUrl)}</a>
    }else if(columnName===InvoiceColumn){
        if(!cell){
            return <></>;
        }
        const stock = props.stocks.find(x=>combinedTicker(x.ticker, x.exchange)===cell)!;
        if(!stock){
            console.error(`unable to find stock with xeroRepeatingInvoiceId ${cell}`)
            return <></>
        }
        
        if(!stock.invoice){
            return <></>;
        }
        const unpaid = stock.unpaid
        let daysLate:number|undefined = stock.daysLate;
        let overdueClass = "";
        if(unpaid){
            
            if(stock.overdue){
                overdueClass = "overdue";
            }
        }
        return <><a target="_blank"
            href={getInvoiceUrl(stock.invoice.invoiceID)}
            rel="noopener noreferrer"
        >     <span>{stock.invoice.invoiceNumber}</span>
        </a>
            <span> - ${stock.invoice.total.toLocaleString()} - 
            <span>{moment(stock.invoice.date).format('DDMMMYY')} - </span>
            {unpaid ? "Unpaid - " : "Paid"}
            { !!daysLate && <> <span className={overdueClass}>{daysLate} days overdue</span> <button className='button-unstyled'> <FaCaretDown onClick={()=>{getEmailTemplate(combinedTicker(stock.ticker, stock.exchange))}} /></button></>}
            </span>
        </>
    }else if(columnName===RepeatColumn){
        if(!cell){
            return <></>;
        }
        const stock = props.stocks.find(x=>combinedTicker(x.ticker, x.exchange)===cell)!;
        if(!stock){
            console.error(`unable to find stock with xeroRepeatingInvoiceId ${cell}`)
            return <></>
        }

       
        const getUrl = (id:string)=>{
            return `https://go.xero.com/RepeatTransactions/Edit.aspx?invoiceID=${id}&type=AR`;
        } 
        const { repeatingInvoice } = stock;

        return <>
            { repeatingInvoice && repeatingInvoice.schedule && <a target="_blank"
                href={getUrl(repeatingInvoice.id)}
                rel="noopener noreferrer"
            >     <span>${repeatingInvoice.total.toLocaleString()} due {moment(repeatingInvoice.schedule.nextScheduledDate).format('DDMMMYY')}</span>
            </a> }
            
        </>
    }
    return null;
}
        
    return <Style>
        <SortableTable headers={getHeaders(user)} data={data} cellFormatter={cellFormatter}  />
    </Style>
}

export default DashboardTable