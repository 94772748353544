import React from 'react'
import styled from 'styled-components';


import { VersionHistory } from '../../model/GenericHistoryViewModel';
import ChangeRowComponent from './ChangeRowComponent';
import moment from 'moment';


interface IProps {
    view: VersionHistory
}

const Style = styled.div`
margin: 2rem 0;

.version-history{    
    margin: 0.3rem 0
}
.version-meta {
    font-size: 0.6rem;
}
`;



const VersionHistoryComponent: React.FC<IProps> = (props: IProps) => {

    const { versionId, changedOn, changedBy, changes } = props.view;

    return <Style >        
        { !changes.length && <p>No Changes</p>}
    { !!changes.length && <>
        <span className='version-meta'>Version {versionId+1} on {moment(changedOn).format('D MMM YYYY hh:mm A')} by {changedBy}</span>
        <table className='version-history'>
            <thead>
            <tr><th>Change Date</th><th>Old Value</th><th>New Value</th></tr>
            </thead>
            
            <tbody>
            {changes.map((x,i)=><ChangeRowComponent key={i} row={x} />)}
            </tbody>
        </table>
        </>}
    </Style>
}

export default VersionHistoryComponent;